"use client"
import { createTheme } from "@mui/material/styles"
import colors from "./colors"
import GlobalStyle from "./GlobalStyle"

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties
    link: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties
    link?: React.CSSProperties
  }
  interface ThemeOptions {
    breakpoints?: {
      values?: {
        xs?: number
        sm?: number
        md?: number
        lg?: number
        xl?: number
      }
    }
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true
    link: true
  }
  export interface Theme {
    breakpoints?: {
      values?: {
        xs?: number
        sm?: number
        md?: number
        lg?: number
        xl?: number
      }
    }
  }
}

let interTheme = createTheme()

interTheme = createTheme(interTheme, {
  direction: "rtl",
  palette: {
    mode: "light",
    primary: {
      main: colors.directAzure,
    },
    secondary: {
      main: colors.directYellow,
    },
  },
  typography: {
    fontFamily: "Ploni, sans-serif;",
    h1: {
      fontSize: 40,
      fontWeight: "400",
      lineHeight: "normal",
      color: colors.directBlack,
    },
    h2: {
      fontSize: 32,
      fontWeight: "400",
      lineHeight: "normal",
      color: colors.directBlack,
    },
    h3: {
      fontSize: 24,
      fontWeight: "400",
      lineHeight: "normal",
      color: colors.directBlack,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: "500",
      lineHeight: "normal",
      color: colors.directBlack,
      [interTheme.breakpoints.up("md")]: {
        fontSize: 20,
      },
    },
    body1: {
      fontSize: 18,
      fontWeight: "400",
      lineHeight: "normal",
      color: colors.directBlack,
    },
    body2: {
      fontSize: 16,
      fontWeight: "400",
      lineHeight: "normal",
      color: colors.directBlack,
    },

    body3: {
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "normal",
      color: colors.directBlack,
    },

    link: {
      fontSize: 16,
      fontWeight: "400",
      lineHeight: "normal",
      color: colors.directBlack,
    },
  },
  // components: {},
  components: {
    MuiCssBaseline: {
      styleOverrides: GlobalStyle,
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // textAlign: "end",
          minHeight: "0px",
          boxShadow: "none",
          "&:not(:last-child)": {
            borderBottom: "1px solid #E6E6E6",
          },
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            backgroundColor: colors.directDisable,
            borderBottom: "none",
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "16px 8px",
          gap: 12,
          flexDirection: "row-reverse",
          "&.Mui-expanded": {
            minHeight: 0,
            padding: "16px 8px 8px 8px",
          },
        },
        content: {
          margin: "0px",
          padding: "0",
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          flexWrap: "wrap",
          padding: "0 44px 24px 16px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: 12,
          backgroundColor: colors.directDisable,
          border: `1px solid ${colors.niolightgrey}`,
          "&": {
            fontSize: 18,
          },
          "&:hover": {
            background: colors.directVeryLightGray,
          },
          "&.Mui-selected": {
            background: colors.directVeryLightGray,
          },
          "&.Mui-selected:hover": {
            background: colors.directVeryLightGray,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "var(--TextField-brandBorderColor)",
          top: 0,
        },
        input: {
          padding: "0px",
          backgroundColor: colors.nioverylightgrey,
          height: "100%",
          fontSize: 14,
          fontWeight: 420,
          paddingLeft: 10,
          "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: colors.marbleGrey,
            fontSize: 16,
          },
        },
        root: {
          borderRadius: 3,
          backgroundColor: colors.nioverylightgrey,
          color: colors.black,
          fontSize: 14,
          border: "none",
          height: "50px",
          borderBottom: `1px solid ${colors.niolightgrey}`,

          "& legend": {
            // the small space in mui input
            width: "0px",
            fontWeight: "normal",
            fontSize: "16px",
          },
          "& fieldset": {
            border: "none",
          },
          "&::placeholder": { color: "#696D7F", fontSize: 14, fontWeight: 300 },

          "& $notchedOutline": {
            borderColor: colors.darkGrey,
            borderWidth: 0.5,
          },
          "&:hover $notchedOutline": {
            // borderColor: colors.transparent,
            borderWidth: 0.5,
          },

          "&$focused $notchedOutline": {
            // borderColor: colors.transparent,
            borderWidth: 0.5,

            // boxShadow: "0px 0px 0px 3.2px rgba(69, 37, 28, 0.25)",
          },
          "&$error $notchedOutline": {
            // borderColor: colors.transparent,
            // boxShadow: "0px 0px 0px 3.2px rgba(171, 78, 78, 0.25)",
            borderWidth: 0.5,
            color: colors.black,
          },
          "&$error": {
            backgroundColor: colors.nioverylightgrey,
            boxShadow: "none",
            borderWidth: 0.5,
            borderBottom: `1px solid ${colors.cyan}`,
          },
          "&$focused": {
            backgroundColor: colors.black,
            boxShadow: "none",
            borderWidth: 0.5,
            borderBottom: `1px solid ${colors.black}`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          direction: "rtl",
          color: "#696D7F",
          fontSize: 14,
          fontWeight: 300,
        },
        outlined: {
          transform: "translate(10px, 17px) scale(1)",
          legend: {
            fontWeight: "bold",
            fontSize: "16px",
          },
          "&$shrink": {
            // the lable when the input is focused
            transform: "translate(0px, -17px) scale(0.75)",
            color: colors.black,
            fontWeight: 420,
            fontSize: "12px",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: "20px",
          fontWeight: 420,
          marginTop: 0,
          marginRight: "0px !important",
          marginLeft: "0px !important",
          "&$error": {
            color: colors.niolightred,
            textAlign: "start",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          fullWidth: true,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          borderRadius: 3,
          height: "45px",
          boxSizing: "border-box",
          "&::placeholder": {
            textOverflow: "ellipsis !important",
            fontSize: 16,
          },
        },
        root: {
          "&$disabled": {
            color: colors.flour,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          fullWidth: true,
          width: "100%",
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          // eslint-disable-next-line quotes
          '&&[class*="MuiOutlinedInput-root"] $input': {
            width: "100%",
            padding: "11px 10px",
            fullWidth: true,
          },
        },
        root: {
          width: "100%",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: colors.black,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          flexBasis: "100%",
          direction: "rtl",
          color: colors.disable,
          fontWeight: "normal",
          fontSize: "16px",
          lineHeight: 1.14,
          // sdkfhdsjfhksdfh
          "&$focused": {
            color: colors.disable,
            "& .MuiFormLabel-asterisk": {
              color: colors.mazdaRed,
            },
          },

          "&$error": {
            color: colors.disable,
          },
        },
        asterisk: {
          marginLeft: "1px",
          verticalAlign: "top",
          color: colors.black,
          display: "none",
          "&$error": {
            color: colors.black,
          },
          "&$focused": {
            color: colors.black,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: "flex-start",
          ".MuiFormGroup-root &": {
            display: "inline-block",
            marginRight: "0",
            marginLeft: "0",
            "& .MuiButtonBase-root": {
              display: "none",
            },
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&:focus-visible": {
            border: "2px dashed white",
            outline: "3px dashed #0e3487",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 6,
          ".MuiButton-endIcon": {
            margin: 0,
            marginRight: 4,
          },
        },
        sizeSmall: {
          fontWeight: 400,
          fontSize: 16,
          padding: "4px 16px",
          height: 32,
        },
        sizeMedium: {
          fontWeight: 500,
          fontSize: 18,
          padding: "7px 16px",
          height: 40,
        },
        sizeLarge: {
          fontWeight: 500,
          fontSize: 18,
          padding: "12px 24px",
          height: 60,
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: colors.directBlack,
            ":disabled": {
              backgroundColor: colors.nioverylightgrey,
              color: colors.directBlack,
            },
          },
        },
        {
          props: { color: "primary", variant: "contained" },
          style: {
            ":hover": {
              backgroundColor: colors.directAzure,
              boxShadow: `inset 0 0 0 2px ${colors.directBlack}`,
            },
          },
        },
        {
          props: { color: "secondary", variant: "contained" },
          style: {
            backgroundColor: "white",
            boxShadow: `inset 0 0 0 1px ${colors.directYellow}`,
            ":hover": {
              boxShadow: `none`,
              backgroundColor: colors.directYellow,
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            // backgroundColor:'transparent',
            border: "none",
            color: "white",
            boxShadow: `inset 0 0 0 1px ${colors.directYellow}`,
            ":hover": {
              boxShadow: `none`,
              backgroundColor: colors.directYellow,
              color: colors.directBlack,
              border: "none",
            },
            ":disabled": {
              backgroundColor: colors.nioverylightgrey,
              color: colors.directBlack,
              border: "none",
              boxShadow: "none",
            },
          },
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 28,
          height: 16,
          padding: 0,
          display: "flex",
        },
        switchBase: {
          padding: 2,
          color: colors.black,
          "&$checked": {
            transform: "translateX(12px)",
          },
        },
        thumb: {
          width: 12,
          height: 12,
          boxShadow: "none",
        },
        colorSecondary: {
          "&$checked": {
            // Controls checked color for the thumb
            color: colors.white,
          },
        },
        track: {
          border: `1px solid ${colors.sand}`,
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor: colors.white,
          "$checked$checked + &": {
            // Controls checked color for the track
            border: `1px solid ${colors.mazdaRed}`,
            opacity: 1,
            backgroundColor: colors.mazdaRed,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          color: colors.sand,
          "&$checked": {
            color: "rgba(177, 17, 22, 0.8);",
          },
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          color: colors.sand,
          "&$checked": {
            color: "rgba(177, 17, 22, 0.8);",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: colors.directVeryLightGray,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: 26,
          paddingBottom: 24,
          transition: "none",
          ".MuiInputBase-root": {
            fontSize: 16,
            "&::before": {
              borderBottomColor: colors.directGray,
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: `2px solid ${colors.directGray}`,
            },
            "&.Mui-focused::before": {
              zIndex: 10000,
              borderBottomColor: colors.directGray,
            },
            "&::after": {
              display: "none",
            },
          },
          ".MuiFormLabel-root": {
            fontSize: 16,
            top: 0,
            lineHeight: "normal",
            right: 0,
            fontWeight: 400,
            color: "black",
            transform: "translate(-12px,10px)",
            // eslint-disable-next-line quotes
            '&[data-shrink="true"]': {
              transform: "translate(0,-28px)",
              fontSize: 14,
              color: "black",
            },
            "&.Mui-error": {
              color: "black",
            },
          },
          ".MuiFormHelperText-root": {
            position: "absolute",
            top: 50,
            fontSize: 14,
            color: colors.directLightRed,
            textAlign: "right",
          },
          input: {
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 10,
            paddingLeft: 10,
            backgroundColor: colors.directDisable,
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: { color: "black" },
        track: { color: "black" },
        rail: { color: "#E9E9E9", height: 4 },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1366,
      xl: 1601,
    },
  },
})
export const theme = interTheme

export type Theme = typeof theme
