/* eslint-disable no-use-before-define */
import Link, { LinkProps } from "next/link"
import { useSearchParams } from "next/navigation"
import { PropsWithChildren, Suspense, forwardRef } from "react"
import { utmParamKeys } from "./utmParams"

interface LinkWithParamsProps extends LinkProps {
  className?: string
  style?: React.CSSProperties
  target?: React.HTMLAttributeAnchorTarget
}

const LinkWithParams = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkWithParamsProps>>(
  ({ href, children, style, target, ...props }, ref) => {
    return (
      <Suspense
        fallback={
          <Link ref={ref} style={style} href={href} target={target} {...props}>
            {children}
          </Link>
        }
      >
        <LinkWithParamsInner ref={ref} href={href} style={style} target={target} {...props}>
          {children}
        </LinkWithParamsInner>
      </Suspense>
    )
  },
)

LinkWithParams.displayName = "LinkWithParams"

const LinkWithParamsInner = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkWithParamsProps>>(
  ({ href, children, style, target, ...props }, ref) => {
    const currentQuery = useSearchParams()
    if (typeof href === "string" && !href.startsWith("/")) {
      return (
        <Link ref={ref} style={style} href={href} target={target} {...props}>
          {children}
        </Link>
      )
    }

    const utmParams = Array.from(currentQuery.entries()).filter(([param]) =>
      utmParamKeys.some((key) => param.includes(key)),
    )

    const hrefParams =
      typeof href === "string" && href.includes("?")
        ? Array.from(new URL(href, "http://dummy.com").searchParams.entries())
        : []

    const allParams = [...utmParams, ...hrefParams]

    const { pathname, hash } =
      typeof href === "string" ? new URL(href, "http://dummy.com") : { pathname: "", hash: "" }

    const query: Record<string, string | string[]> = {}
    allParams.forEach(([key, value]) => {
      if (key in query) {
        if (Array.isArray(query[key])) {
          ;(query[key] as string[]).push(value)
        } else {
          query[key] = [query[key] as string, value]
        }
      } else {
        query[key] = value
      }
    })

    const finalHref = {
      pathname: pathname || "",
      query,
      hash: hash,
    }

    return (
      <Link ref={ref} style={style} href={finalHref} target={target} {...props}>
        {children}
      </Link>
    )
  },
)

LinkWithParamsInner.displayName = "LinkWithParamsInner"

export default LinkWithParams
